<template>
  <component
    :is="href ? 'a': fallbackTag"
    :href="href"
    v-bind="$attrs"
    class="VAnchor"
    @click.prevent="openIn"
  >
    <slot />
  </component>
</template>

<script>
import { Plugins } from '@capacitor/core'

const { Browser } = Plugins

/**
 * @module VAnchor
 */
export default {
  name: 'VAnchor',
  props: {
    href: {
      type: String,
      default: ''
    },
    fallbackTag: {
      type: String,
      default: 'span'
    }
  },
  methods: {
    openIn (e) {
      return Browser.open({ url: this.href, toolbarColor: '#000000', windowName: this.$attrs.name })
    }
  }
}
</script>
