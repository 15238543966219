<template>
  <el-popover
    :disabled="!hasMoreThanOne"
    trigger="hover"
  >
    <div>
      <div
        v-for="country in leftCountries"
        :key="country.iso_alpha2"
        data-testid="extraCountryItem"
      >
        <country-flag :country="country.iso_alpha2" />
        {{ country.name }}
      </div>
    </div>
    <div slot="reference">
      <div v-if="mainCountry" class="info__value is-flex is-aligned-middle">
        <country-flag :country="mainCountry.iso_alpha2" />
        <span v-if="hasMoreThanOne" data-testid="extraCountries">&nbsp;(+{{ numberOfExtraCountries }})</span>
      </div>
    </div>
  </el-popover>
</template>

<script>
import { mapGetters } from 'vuex'

/**
 * @module MentionItemCountriesDisplay
 */
export default {
  name: 'MentionItemCountriesDisplay',
  props: {
    countries: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters(['getCountryByIso2']),
    /**
     * Returns the main country for the mention
     * @return {HF_Country}
     */
    mainCountry () {
      return this.getCountryByIso2(this.countries[0])
    },
    /**
     * Returns the extra countries for display in a dropdown
     * @return {HF_CountriesList}
     */
    leftCountries () {
      return this.countries.slice(1).map(c => this.getCountryByIso2(c)).filter(c => c)
    },
    /**
     * The number of extra countries
     * @return {number}
     */
    numberOfExtraCountries () {
      return this.leftCountries.length
    },
    /**
     * Whether we have more than one country
     * @return {boolean}
     */
    hasMoreThanOne () {
      return !!this.numberOfExtraCountries
    }
  }
}
</script>
