<template>
  <div class="MentionItemBase__metaField">
    <div
      v-for="language in mappedLanguages"
      :key="language.name"
      data-testid="LanguageItem"
    >
      {{ language.name }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

/**
 * @module MentionItemLanguagesDisplay
 */
export default {
  name: 'MentionItemLanguagesDisplay',

  props: {
    languages: {
      type: Array,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      storedLanguages: 'languages'
    }),

    mappedLanguages () {
      return this.storedLanguages.filter(language => {
        return this.languages.includes(language.code)
      })
    }
  }
}
</script>
