<template>
  <div class="MentionItemBase Card is-height-auto has-background-white">
    <div class="columns is-mobile is-multiline is-gapless is-marginless">
      <div
        :class="{'is-9-tablet': mentionImage }"
        class="is-12-mobile is-order-2-mobile is-column column is-expanded"
      >
        <div
          v-if="$slots['header-actions'] || hasCountries || mediaTitleName"
          class="Card__header is-flex is-aligned-middle"
        >
          <div class="is-expanded is-flex is-aligned-middle">
            <mention-item-countries-display
              v-if="hasCountries"
              :countries="countries"
            />

            <v-anchor :href="source" target="_blank">
              <h4
                v-if="mediaTitleName"
                data-testid="MentionMediaTitle"
                class="has-text-weight-semibold has-text-grey"
              >
                {{ mediaTitleName }}
              </h4>
            </v-anchor>
          </div>

          <slot name="header-actions" />
          <slot name="actions" />
        </div>

        <div class="Card__body">
          <div class="p-b-m p-h-m">
            <div>
              <div class="is-flex is-aligned-middle m-b-s">
                <span v-if="publishedAt" class="is-italic m-r-s">
                  {{ publicationDate(publishedAt) }}
                </span>

                <span v-if="showHarvestDate" class="is-italic m-r-s">
                  | Harvested at: {{ publicationDate(harvestedAt, 'DD MMMM YYYY - HH:mm') }}
                </span>

                <div v-if="duration">
                  -
                  <v-icon type="watch_3" size="sm" />
                  {{ datetime(duration.start, 'h.mma') }}-{{ datetime(duration.end, 'h.mma') }}
                </div>
              </div>

              <v-anchor
                v-if="contentTitle"
                :href="source"
                target="_blank"
              >
                <div
                  :title="contentTitle"
                  class="has-text-weight-bold m-b-s leading-none"
                >
                  {{ truncate(contentTitle, 100) }}
                </div>
              </v-anchor>

              <div>
                <p
                  class="Mention-item__body break-words is-inline"
                  v-html="contentBodyHighlighted || contentBody"
                />
                <slot name="after-content" />
              </div>
            </div>
          </div>
        </div>

        <div class="Card__footer has-border">
          <!-- Secondary Meta Fields-->
          <div class="is-flex is-aligned-middle has-text-grey m-b-m can-wrap">
            <mention-item-languages-display
              v-if="hasLanguages"
              :languages="languages"
            />

            <div :title="$t('general.media_type')" class="MentionItemBase__metaField">
              <clipping-type-icon :type="type" />
              {{ $t(mediaTypes[type].label) }}
            </div>

            <div v-if="sentiment" class="MentionItemBase__metaField">
              <v-icon :type="sentiments[sentiment].icon" />
              {{ $t(sentiments[sentiment].label) }}
            </div>
          </div>
          <div class="is-flex is-aligned-middle has-text-grey can-wrap">
            <div
              v-if="visitsPrMonth"
              :title="$t('general.visits_pr_month')"
              class="MentionItemBase__metaField"
            >
              <el-tooltip placement="top">
                <div slot="content">
                  <a
                    href="https://similarweb.com"
                    target="_blank"
                  >
                    {{ $t('components.streams.mention_item.insights_by', { provider: 'SimilarWeb' }) }}
                  </a>
                </div>

                <img
                  :src="$asset('/images/components/mentions/simweb.png')"
                  width="15"
                  class="m-r-xs"
                >
              </el-tooltip>
              {{ $t('components.streams.mention_item.visits_per_month') }}:
              {{ abbr(visitsPrMonth, impressionAbbreviationCutoff) }}
            </div>

            <div
              :title="$t('general.impressions')"
              class="MentionItemBase__metaField"
              data-testid="mentionImpressions"
            >
              <v-icon type="eye" />
              {{ $t('general.ots') }}
              <template v-if="impressions">
                {{ formatImpressionsValue(impressions) }}
              </template>
              <template v-else>
                {{ $t('general.not_available') }}
              </template>
            </div>

            <div v-if="estimatedClicks" class="MentionItemBase__metaField">
              {{ $t('general.estimated_clicks') }}:
              {{ abbr(estimatedClicks, impressionAbbreviationCutoff) }}
            </div>

            <div v-if="presence" class="MentionItemBase__metaField">
              {{ $t(presences[presence].label) }}
            </div>

            <div v-if="type === 'print'" class="MentionItemBase__metaField">
              {{ $t('general.is_advertisement') }}: {{ isAdvertisement ? $t('general.yes') : $t('general.no') }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="mentionImage" class="is-order-1-mobile column is-12-mobile is-3-tablet is-flex">
        <v-anchor
          :href="source"
          :style="{ backgroundImage: `url(${mentionImage})` }"
          :class="{ 'to-top': isSocialMediaType }"
          class="MentionItemBase__Image is-cover-image"
          target="_blank"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TruncateMixin from '@hypefactors/shared/js/mixins/TruncateMixin'
import PublicationDateMixin from '@hypefactors/shared/js/mixins/PublicationDateMixin'
import DatetimeMixin from '@hypefactors/shared/js/mixins/DatetimeMixin'
import FormatImpressionsValueMixin from '@hypefactors/shared/js/mixins/FormatImpressionsValueMixin'
import AbbrMixin from '@hypefactors/shared/js/mixins/AbbrMixin'

import { MEDIA_TYPES, SOCIAL_MEDIA_TYPES } from '@hypefactors/shared/js/constants/mediaTypes'
import { SENTIMENT_TYPES } from '@hypefactors/shared/js/constants/sentimentTypes'
import { PRESENCE_TYPES } from '@hypefactors/shared/js/constants/presenceTypes'

import VAnchor from '@hypefactors/shared/js/components/core/VAnchor'
import MentionItemCountriesDisplay from '@hypefactors/shared/js/components/streams/MentionItemCountriesDisplay'
import MentionItemLanguagesDisplay from '@hypefactors/shared/js/components/streams/MentionItemLanguagesDisplay'

/**
 * @module MentionItemBase
 */
export default {
  name: 'MentionItemBase',

  components: { VAnchor, MentionItemCountriesDisplay, MentionItemLanguagesDisplay },

  mixins: [TruncateMixin, PublicationDateMixin, DatetimeMixin, FormatImpressionsValueMixin, AbbrMixin],

  props: {
    mediaTitleName: {
      type: String,
      default: ''
    },
    contentTitle: {
      type: String,
      default: ''
    },
    contentBodyHighlighted: {
      type: String,
      default: ''
    },
    contentBody: {
      type: String,
      default: ''
    },
    publishedAt: {
      type: String,
      default: ''
    },
    harvestedAt: {
      type: String,
      default: ''
    },
    countries: {
      type: Array,
      default: () => []
    },
    languages: {
      type: Array,
      default: () => []
    },
    sentiment: {
      type: String,
      default: ''
    },
    presence: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      required: true
    },
    visitsPrMonth: {
      type: Number,
      default: 0
    },
    impressions: {
      type: Number,
      default: 0
    },
    estimatedClicks: {
      type: Number,
      default: 0
    },
    image: {
      type: String,
      default: ''
    },
    source: {
      type: String,
      default: ''
    },
    /**
     * @type {HF_Mention.duration}
     */
    duration: {
      type: Object,
      default: null
    },
    adLikelihood: {
      type: Number,
      default: 0
    },
    isAdvertisement: {
      type: Boolean,
      default: false
    },
    showHarvestDate: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    impressionAbbreviationCutoff: () => 1000000,
    presences: () => PRESENCE_TYPES,
    sentiments: () => SENTIMENT_TYPES,
    mediaTypes: () => MEDIA_TYPES,
    placeholderImage () {
      return this.$asset('/images/general/loading-animation.gif')
    },
    /**
     * Fetches the Mention image or the fallback image.
     * @return {string|*}
     */
    mentionImage () {
      return this.image
    },
    hasCountries () {
      return this.countries && this.countries.length
    },
    hasLanguages () {
      return this.languages && this.languages.length
    },
    isSocialMediaType () {
      return SOCIAL_MEDIA_TYPES.includes(this.type)
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.MentionItemBase {
  &__Image {
    width: 100%;
    height: 190px;
    display: block;
    @include mobile {
      margin-bottom: $margin;
    }
  }

  .Card__header {
    @include mobile {
      padding-right: 0;
    }
  }

  .Card__body {
    padding: 0;
    margin: 0;

    h3 {
      font-weight: bold;
      margin-bottom: $margin;
    }

    .highlight {
      background: $hf__color-primary;
      color: $hf__color-white;
      padding: 0.1rem $padding-tiny;
      line-height: 1.1;
      font-weight: bold;
    }

    .requiresSocialToken {
      position: absolute;
      top: -5px;
      right: 0;
    }
  }

  .Card__footer {
    .info {
      display: flex;
      flex-direction: column;

      .info__label {
        text-transform: uppercase;
        font-size: $hf__font-label;
        color: $hf__text-muted;
      }

      .info__value {
        font-weight: bold;
      }
    }
  }

  &__metaField {
    padding-right: $padding;
    border-right: 1px solid $grey-light;
    margin-right: $margin;
    display: flex;
    align-items: center;
    font-weight: $hf__font-weight-bold;

    @include rtl {
      border-right: none;
      border-left: 1px solid $grey-light;
      padding-left: $padding;
      padding-right: 0;
      margin-left: $margin;
      margin-right: 0;
    }

    &:last-of-type {
      border: none;
      padding: 0;
      margin: 0
    }
  }

  @include tablet {

    &__Image {
      height: 100%;
    }
  }
}
</style>
