export const PRESENCE_TYPES = {
  mentioned: {
    label: 'components.clipping_topics.mentioned'
  },
  secondary: {
    label: 'components.clipping_topics.secondary'
  },
  exclusive: {
    label: 'components.clipping_topics.exclusive'
  }
}

export const PRESENCE_TYPES_ARRAY = Object.entries(PRESENCE_TYPES).map(([key, presence]) => ({ key, ...presence }))
